import { Button } from "@mantine/core";

import { useLock } from "../contexts/LockContext";
import { useUiRevision } from "../contexts/UiRevisionContext";

export default function LockButton() {
  const { locked, setLocked } = useLock();
  const { uiRevision, setUiRevision } = useUiRevision();
  return (
    <>
      <Button
        style={{ pos: "top", paddingRight: 5, paddingLeft: 5 }}
        variant="default"
        size="sm"
        onClick={() => {
          setLocked(!locked);
          setUiRevision(uiRevision + 1);
        }}
      >
        {locked ? "Unlock" : "Lock"}
      </Button>
    </>
  );
}
