import { Button } from "@mantine/core";
import _ from "lodash";

import { useTabConfigs } from "../contexts/TabConfigContext";
import { useActiveTab } from "../contexts/ActiveTabContext";

export default function AddPlotButton() {
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { activeTab } = useActiveTab();

  const handleAddPlot = () => {
    if (activeTab && activeTab in tabConfigs) {
      let plotId = "";
      do {
        plotId = _.uniqueId("Plot-");
      } while (Object.keys(tabConfigs[activeTab].plotConfigs).includes(plotId));
      tabConfigs[activeTab].plotConfigs[plotId] = {
        title: plotId,
        xVar: null,
        yAxes: [],
      };
      setTabConfigs({ ...tabConfigs });
    } else {
      console.log("Cannot add plot, tab not selected");
    }
  };

  return (
    <>
      <Button
        style={{ pos: "top", paddingLeft: 5, paddingRight: 5 }}
        variant="default"
        size="sm"
        onClick={handleAddPlot}
      >
        Add Plot
      </Button>
    </>
  );
}
