import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

import { SignalNames } from "../services/session/file/FileClasses";

type SignalNamesState = {
  signalNames: SignalNames;
  setSignalNames: React.Dispatch<React.SetStateAction<SignalNames>>;
};

const SignalNamesContext = createContext<SignalNamesState | undefined>(
  undefined,
);

export function SignalNamesProvider({ children }: PropsWithChildren<{}>) {
  const [signalNames, setSignalNames] = useState<SignalNames>([]);
  return (
    <SignalNamesContext.Provider value={{ signalNames, setSignalNames }}>
      {children}
    </SignalNamesContext.Provider>
  );
}

export function useSignalNames(): SignalNamesState {
  const signalNamesState = useContext(SignalNamesContext);
  if (!signalNamesState) {
    throw new Error("useSignalNames must be within SignalNamesProvider");
  }
  return signalNamesState;
}
