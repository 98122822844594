import { useLock } from "../../contexts/LockContext";

import { ActionIcon } from "@mantine/core";
import { IconHome } from "@tabler/icons-react";
import { useXAxisRange } from "../../contexts/XAxisRangeContext";
import React from "react";
import { useMinMaxXAllowed } from "../../contexts/MinMaxXAllowedContext";

export default function PlotButtons() {
  const { locked } = useLock();
  const { setXAxisRange } = useXAxisRange();
  const { minMaxXAllowed } = useMinMaxXAllowed();
  return (
    <div style={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}>
      {locked && (
        <ActionIcon variant="transparent">
          <IconHome
            size={20}
            onClick={() => {
              console.log("Home clicked");
              if (minMaxXAllowed) {
                setXAxisRange([...minMaxXAllowed]);
              }
            }}
          />
        </ActionIcon>
      )}
    </div>
  );
}
