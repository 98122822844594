import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type UiRevisionState = {
  uiRevision: number;
  setUiRevision: React.Dispatch<React.SetStateAction<number>>;
};

const UiRevisionContext = createContext<UiRevisionState | undefined>(undefined);

export function UiRevisionProvider({ children }: PropsWithChildren<{}>) {
  const [uiRevision, setUiRevision] = useState(0);
  return (
    <UiRevisionContext.Provider value={{ uiRevision, setUiRevision }}>
      {children}
    </UiRevisionContext.Provider>
  );
}

export function useUiRevision(): UiRevisionState {
  const uiRevisionState = useContext(UiRevisionContext);
  if (!uiRevisionState) {
    throw new Error("useUiRevision must be inside UiRevisionProvider");
  }
  return uiRevisionState;
}
