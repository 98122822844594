import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

type MinMaxXAllowedState = {
  minMaxXAllowed: number[] | undefined;
  setMinMaxXAllowed: React.Dispatch<React.SetStateAction<number[] | undefined>>;
};

const MinMaxXAllowedContext = createContext<MinMaxXAllowedState | undefined>(
  undefined,
);

export function MinMaxXAllowedProvider({ children }: PropsWithChildren<{}>) {
  const [minMaxXAllowed, setMinMaxXAllowed] = useState<number[] | undefined>(
    undefined,
  );
  return (
    <MinMaxXAllowedContext.Provider
      value={{ minMaxXAllowed, setMinMaxXAllowed }}
    >
      {children}
    </MinMaxXAllowedContext.Provider>
  );
}

export function useMinMaxXAllowed(): MinMaxXAllowedState {
  const minMaxXAllowedState = useContext(MinMaxXAllowedContext);
  if (!minMaxXAllowedState) {
    throw new Error("useMinMaxXAllowed must be within minMaxXAllowedProvider");
  }
  return minMaxXAllowedState;
}
