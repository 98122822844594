import { TreeItem } from "@mui/x-tree-view/TreeItem";
import React from "react";

import { Directory } from "../../services/session/file/FileClasses";

interface Props {
  parentDirectoryPath: string;
  directory: Directory;
}

export default function DirectoryItem({
  parentDirectoryPath,
  directory,
}: Props) {
  const directoryPath = parentDirectoryPath + "/" + directory.name;
  return (
    <TreeItem nodeId={directoryPath} label={directory.name} key={directoryPath}>
      {directory.bzFiles && (
        <>
          {directory.bzFiles.map((bzFile) => (
            <TreeItem
              nodeId={directoryPath + "/" + bzFile}
              label={bzFile}
              key={parentDirectoryPath + "/" + bzFile}
            />
          ))}
        </>
      )}
      {directory.directories && (
        <>
          {Object.values(directory.directories).map((subDirectory) => (
            <DirectoryItem
              parentDirectoryPath={directoryPath}
              directory={subDirectory}
              key={directoryPath + "/" + subDirectory.name}
            />
          ))}
        </>
      )}
    </TreeItem>
  );
}
