import axios, { AxiosInstance } from "axios";

import SessionApi from "./session/SessionApi";

export class API {

  private apiClient: AxiosInstance;
  public sessionApi: SessionApi;

  constructor( basePath: string ) {
    this.apiClient = axios.create({ 
      baseURL: basePath,
      headers: {
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
        "Expires": "0"
      },
    });
    this.sessionApi = new SessionApi(this.apiClient);
  }
}
