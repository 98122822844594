import React, {
    createContext,
    useContext,
    useState,
    PropsWithChildren,
} from "react";

import { API } from "../services/Api"

type ApiState = {
    api: API;
    setApi: React.Dispatch<React.SetStateAction<API>>
}

const ApiContext = createContext<ApiState | undefined>(undefined);

export function ApiProvider({ children }: PropsWithChildren<{}>) {
    const [api, setApi] = useState<API>(new API(""));
    return (
        <ApiContext.Provider value={{ api, setApi }}>
            {children}    
        </ApiContext.Provider>
    )
}

export function useApi(): ApiState {
    const apiState = useContext(ApiContext);
    if (!apiState) {
        throw new Error("useApi must be within ApiProvider")
    }
    return apiState
}
