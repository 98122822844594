import React, { useEffect, useState } from "react";
import { Select, ActionIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconFolder } from "@tabler/icons-react";

import FileTreeModal from "./FileTreeModal";
import { useApi } from "../../contexts/ApiContext";
import { useFileDirectory } from "../../contexts/FileDirectoryContext";

interface Props {
  setBinFiles: React.Dispatch<React.SetStateAction<string[]>>;
  selectedBzFile: string | null;
  setSelectedBzFile: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedBinFile: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function BzFileSelect({
  setBinFiles,
  selectedBzFile,
  setSelectedBzFile,
  setSelectedBinFile,
}: Props) {
  const { api } = useApi();
  const [files, setFiles] = useState<string[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const { fileDir } = useFileDirectory();
  const [bzDropdownOpenTrig, setBzDropdownOpenTrig] = useState(false);
  useEffect(() => {
    if (selectedBzFile) {
      api.sessionApi.fileApi.setBz(fileDir, selectedBzFile).then((apiRes) => {
        setBinFiles(apiRes ? apiRes : []);
      });
    } else {
      setBinFiles([]);
    }
    setSelectedBinFile(null);
  }, [selectedBzFile]);

  return (
    <>
      <Select
        searchable
        value={selectedBzFile}
        onChange={(value) => {
          if (value !== selectedBzFile) {
            setSelectedBzFile(value);
          }
        }}
        data={files}
        onDropdownOpen={() => {
          setBzDropdownOpenTrig(!bzDropdownOpenTrig);
        }}
        placeholder="Select .bz File"
        leftSection={
          <ActionIcon variant="transparent" onClick={open}>
            <IconFolder color="teal" strokeWidth={1} size={26}></IconFolder>
          </ActionIcon>
        }
      />
      <FileTreeModal
        opened={opened}
        close={() => {
          close();
        }}
        setBzFiles={setFiles}
        setSelectedBz={setSelectedBzFile}
        bzDropdownOpenTrig={bzDropdownOpenTrig}
      />
    </>
  );
}
