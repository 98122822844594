import React, { forwardRef, useState } from "react";

import PlotButtons from "./PlotButtons";
import Plot from "./Plot";
import PlotMenu from "./PlotMenu";
import { useTabConfigs } from "../../contexts/TabConfigContext";

interface Props {
  tabId: string;
  plotId: string;
  focusedPlot: string;
  setFocusedPlot: React.Dispatch<React.SetStateAction<string>>;
  sampleMode: string;
  [propName: string]: any;
}

export const PlotWrapper = forwardRef(
  ({ tabId, plotId, focusedPlot, setFocusedPlot, sampleMode, ...fwdProps }: Props, ref: any) => {
    const [opened, setOpened] = useState(false);
    const [menuLeft, setMenuLeft] = useState(0);
    const [menuTop, setMenuTop] = useState(0);
    const { tabConfigs } = useTabConfigs();
    const [plotTitle, setPlotTitle] = useState<string>(
      tabConfigs[tabId].plotConfigs[plotId].title,
    );
    const [menuFocus, setMenuFocus] = useState(false);
    const [localUiRevision, setLocalUiRevision] = useState<number>(0);
    return (
      <div>
        <div
          onMouseDownCapture={(e) => {
            if (e.button === 2) {
              e.stopPropagation();
            }
          }}
          onClick={(e) => {
            if (e.button === 0) {
              setMenuFocus(false);
              setOpened(false);
            }
          }}
          ref={ref}
          style={{
            border: "1px solid rgba(253,126,20,0.3)",
            borderRadius: "4px",
            overflow: "hidden",
            ...fwdProps.style,
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            setMenuLeft(e.pageX);
            setMenuTop(e.pageY);
            setOpened(true);
          }}
          className={fwdProps.className}
          onMouseDown={fwdProps.onMouseDown}
          onMouseUp={fwdProps.onMouseUp}
          onTouchEnd={fwdProps.onTouchEnd}
        >
          <Plot
            plotTitle={plotTitle}
            plotId={plotId}
            tabId={tabId}
            height={parseInt(fwdProps.style.height)}
            width={parseInt(fwdProps.style.width)}
            plotData={[]}
            plotType={
              tabConfigs[tabId].plotConfigs[plotId].xVar === "tick_count"
                ? "line"
                : "scatter"
            }
            localUiRevision={localUiRevision}
            setLocalUiRevision={setLocalUiRevision}
            setMenuOpened={setOpened}
            focusedPlot={focusedPlot}
            setFocusedPlot={setFocusedPlot}
          />
          <PlotButtons />

          {fwdProps.children}
        </div>
        <div
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <PlotMenu
            plotTitle={plotTitle}
            setPlotTitle={setPlotTitle}
            left={menuLeft}
            top={menuTop}
            opened={opened}
            setOpened={setOpened}
            plotId={plotId}
            tabId={tabId}
            localUiRevision={localUiRevision}
            setLocalUiRevision={setLocalUiRevision}
            menuFocus={menuFocus}
            setMenuFocus={setMenuFocus}
            sampleMode={sampleMode}
          />
        </div>
      </div>
    );
  },
);
