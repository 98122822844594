import { useState } from "react";
import { SegmentedControl } from "@mantine/core";
import { useApi } from "../contexts/ApiContext";

interface Props {
    extractor: string;
    setExtractor: React.Dispatch<React.SetStateAction<string>>;
}

export default function ExtractorControl({ extractor, setExtractor }: Props) {
    const { api, setApi } = useApi();
    return <>
        <SegmentedControl
            value={extractor}
            onChange={
                (val)=>{
                    const prevVal = extractor
                    setExtractor(val)
                    api.sessionApi.fileApi.setExtractor(val)
                    .catch((e) => {setExtractor(prevVal)})
                }
            }
            data={["S10", "S11"]}
        />
    </>
}