import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

type FaultSelections = { [status: string]: string[] };

type FaultSelectionsState = {
  faultSelections: FaultSelections;
  setFaultSelections: React.Dispatch<React.SetStateAction<FaultSelections>>;
};

const FaultSelectionsContext = createContext<FaultSelectionsState | undefined>(
  undefined,
);

export function FaultSelectionsProvider({ children }: PropsWithChildren<{}>) {
  const [faultSelections, setFaultSelections] = useState<FaultSelections>({});
  return (
    <FaultSelectionsContext.Provider
      value={{ faultSelections, setFaultSelections }}
    >
      {children}
    </FaultSelectionsContext.Provider>
  );
}

export function useFaultSelections(): FaultSelectionsState {
  const faultSelectionsState = useContext(FaultSelectionsContext);
  if (!faultSelectionsState) {
    throw new Error(
      "useFaultSelections must be within FaultSelectionsProvider",
    );
  }
  return faultSelectionsState;
}
