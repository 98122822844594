import { AxiosInstance, AxiosResponse } from "axios";
import { notifications } from "@mantine/notifications";

import { Directory, SignalNames } from "./FileClasses";

class FileApi {
  readonly pathPrefix = "/file";
  private apiClient: AxiosInstance;
  private setBzController: null | AbortController = null;
  private setBinController: null | AbortController = null;
  private systemEntriesController: null | AbortController = null;
  private signalNamesController: null | AbortController = null;
  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }
  async extract(fileName: string): Promise<string[] | undefined> {
    /**
     * Extract the bz file
     *
     * @param fileName - Name of the .bz file to extract
     *
     * @returns A list of .bin files from the specified .bz file
     */
    try {
      const response: AxiosResponse<string[]> = await this.apiClient.post(
        `${this.pathPrefix}/extract/${fileName}`,
        { t: new Date().getTime() }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async setExtractor(
    extractor: string
  ): Promise<undefined> {
    /**
     * Sets the extractor
     * 
     * @param extractor - The extractor identifier
     * 
     * @returns nothing
     */
    try {
      const response: AxiosResponse<undefined> = await this.apiClient.post(
        `${this.pathPrefix}/extractor/${extractor}`,
        { t: new Date().getTime() },
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async setBz(
    fileDir: string,
    fileName: string,
  ): Promise<string[] | undefined> {
    /**
     * Sets the bz file
     *
     * @param fileDir - Full path to the directory of the file
     * @param fileName - Name of the .bz file
     *
     * @returns A list of .bin files from the specified .bz file if they exist
     */
    if (this.setBzController !== null) {
      this.setBzController.abort();
    }
    this.setBzController = new AbortController();
    try {
      const response: AxiosResponse<string[]> = await this.apiClient.post(
        `${this.pathPrefix}/bz/${fileName}`,
        { t: new Date().getTime() },
        { 
          headers: { fileDir: fileDir },
          signal: this.setBzController.signal
        },
      );
      return response.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.setBzController = null;
    }
  }
  async setBin(
    fileName: string,
  ): Promise<number[] | undefined> {
    /**
     * Sets the bin file to query data from
     *
     * @param fileName - Name of the .bin file
     *
     * @returns Nothing
     */
    if (this.setBinController !== null) {
      this.setBinController.abort();
    }
    this.setBinController = new AbortController();
    try {
      const response: AxiosResponse<number[]> = await this.apiClient.post(
        `${this.pathPrefix}/bin/${fileName}`,
        { t: new Date().getTime() },
        {
          signal: this.setBinController.signal
        }
      );
      return response.data;
    } catch (err) {
      notifications.show({
        title: `Error setting .bin file, try selecting again`,
        message: `${err}`,
        color: "red",
        withCloseButton: true
      })
      console.log(err);
    } finally {
      this.setBinController = null;
    }
  }

  async systemEntries(fileDir: string): Promise<Directory | undefined> {
    /**
     * Get the directories and bz files up to 2 directories deep of the backend server
     *
     * @param fileDir - Full path to the directory to get entries for
     *
     * @returns SystemEntries - An object containing a list of bz files inside the fileDir
     * and a list of a DirectoryEntries Object which has key, values pairs of directory name
     * and list of bz files within respectively
     */
    if (this.systemEntriesController !== null) {
      this.systemEntriesController.abort();
    }
    this.systemEntriesController = new AbortController();
    try {
      const response: AxiosResponse<Directory> = await this.apiClient.get(
        `${this.pathPrefix}/system-entries`,
        { 
          headers: { fileDir: fileDir },
          params: { t: new Date().getTime() },
          signal: this.systemEntriesController.signal
        },
      );
      return response.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.systemEntriesController = null;
    }
  }

  async signalNames(fileName: string): Promise<SignalNames | undefined> {
    /**
     * Get the signal names in the .bin file
     *
     * @param fileName - The .bin file name to get signal names from
     *
     * @returns SignalNames - An object containing the signal names in the .bin file
     * separated as key value pairs where the key is the source such as bram and the value
     * value are the signal names
     */
    if (this.signalNamesController !== null) {
      this.signalNamesController.abort();
    }
    this.signalNamesController = new AbortController();
    try {
      const response: AxiosResponse<SignalNames> = await this.apiClient.get(
        `${this.pathPrefix}/${fileName}/signal-names`,
        { 
          params: { t: new Date().getTime() }, 
          signal: this.signalNamesController.signal
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.signalNamesController = null;
    }
  }
}

export default FileApi;
