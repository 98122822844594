import { useState, useEffect } from "react";
import { MantineProvider, Grid } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/core/styles.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./App.css";

import { TabConfigsProvider } from "./contexts/TabConfigContext";
import { SessionMeta } from "./services/session/SessionClasses";
import LandingPage from "./components/LandingPage";
import { LockProvider } from "./contexts/LockContext";
import { ActiveTabProvider } from "./contexts/ActiveTabContext";
import AddPlotButton from "./components/AddPlotButton";
import LockButton from "./components/LockButton";
import BzFileSelect from "./components/BzFileSelect/BzFileSelect";
import BinFileSelect from "./components/BinFileSelect";
import { FileDirectoryProvider } from "./contexts/FileDirectoryContext";
import Tabs from "./components/Tabs/Tabs";
import ExtractFileButton from "./components/ExtractFileButton";
import { SignalNamesProvider } from "./contexts/SignalNamesContext";
import { DataProvider } from "./contexts/DataContext";
import SaveButton from "./components/SaveButton";
import { XAxisRangeProvider } from "./contexts/XAxisRangeContext";
import { MinMaxXAllowedProvider } from "./contexts/MinMaxXAllowedContext";
import { UiRevisionProvider } from "./contexts/UiRevisionContext";
import FaultsButton from "./components/FaultsButton";
import { FaultData } from "./services/session/data/DataClasses";
import { FaultDataProvider } from "./contexts/FaultDataContext";
import { FaultSelectionsProvider } from "./contexts/FaultSelectionsContext";
import ResetYScaleButton from "./components/ResetYScaleButton";
import { useApi } from "./contexts/ApiContext";
import { API } from "./services/Api";
import LandingPageButton from "./components/LandingPageButton";
import SampleModeControl from "./components/SampleModeControl";
import ExtractorControl from "./components/ExtractorControl";
import ExportButton from "./components/ExportButton";
import AlignViewButton from "./components/AlignViewButton";

function App() {
  const [sessionMeta, setSessionMeta] = useState<SessionMeta | undefined>();
  const [selectedBzFile, setSelectedBzFile] = useState<string | null>(null);
  const [selectedBinFile, setSelectedBinFile] = useState<string | null>(null);
  const [binFiles, setBinFiles] = useState<string[]>([]);
  const [uiRevision, setUiRevision] = useState(1);
  const [backend, setBackend] = useState<string | null>(null);
  const [backendSelection, setBackendSelection] = useState<string | null>(null);
  const [backendError, setBackendError] = useState<boolean>(false);
  const backendUrls: {[name: string]: string} = {
    "Local": "http://localhost:8000",
    "Race": "https://icm-race-server.jagracingtools.com",
	"Test": "https://icm-test-server.jagracingtools.com"
  };
  const [sampleMode, setSampleMode] = useState("Every Nth");
  const [extractor, setExtractor] = useState("S11");
  const { api, setApi } = useApi();

  useEffect(() => {
    setSelectedBzFile(null);
    if (backend !== null) {
      const api = new API(backendUrls[backend]);
      setApi(api);
      api.sessionApi.getSession().then((apiRes) => {
        setSessionMeta(apiRes);
        if (apiRes === undefined) {
          setBackendError(true);
        } else {
          setBackendError(false);
        }
      });
    }
  }, [backend]);

  return (
    <MantineProvider defaultColorScheme={"dark"}>
      <Notifications />
      {sessionMeta ? (
        <LockProvider>
          <ActiveTabProvider>
            <TabConfigsProvider
              initialTabConfigs={
                sessionMeta.tabConfigs ? sessionMeta.tabConfigs : {}
              }
            >
              <SignalNamesProvider>
                <DataProvider>
                  <FaultDataProvider>
                    <FaultSelectionsProvider>
                      <UiRevisionProvider>
                        <XAxisRangeProvider>
                          <MinMaxXAllowedProvider>
                            <FileDirectoryProvider>
                              <Grid style={{ padding: 5 }} gutter={5}>
                                <Grid.Col span="content">
                                  <SaveButton />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <AddPlotButton />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <LockButton />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <ResetYScaleButton />
                                </Grid.Col>

                                <Grid.Col span="content">
                                  <BzFileSelect
                                    setBinFiles={setBinFiles}
                                    selectedBzFile={selectedBzFile}
                                    setSelectedBzFile={setSelectedBzFile}
                                    setSelectedBinFile={setSelectedBinFile}
                                  />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <ExtractFileButton
                                    binFiles={binFiles}
                                    setBinFiles={setBinFiles}
                                    selectedBzFile={selectedBzFile}
                                  />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <BinFileSelect
                                    files={binFiles}
                                    selectedBinFile={selectedBinFile}
                                    setSelectedBinFile={setSelectedBinFile}
                                  />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <FaultsButton />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <SampleModeControl
                                    sampleMode={sampleMode}
                                    setSampleMode={setSampleMode}
                                  />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <ExtractorControl
                                    extractor={extractor}
                                    setExtractor={setExtractor}
                                  />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <ExportButton />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <AlignViewButton />
                                </Grid.Col>
                                <Grid.Col span="content">
                                  <LandingPageButton
                                    setSessionMeta={setSessionMeta}
                                    backend={backend}
                                    setBackend={setBackend}
                                  />
                                </Grid.Col>
                              </Grid>
                              <Tabs
                                selectedBinFile={selectedBinFile}
                                setSelectedBinFile={setSelectedBinFile}
                                sampleMode={sampleMode}
                              />
                            </FileDirectoryProvider>
                          </MinMaxXAllowedProvider>
                        </XAxisRangeProvider>
                      </UiRevisionProvider>
                    </FaultSelectionsProvider>
                  </FaultDataProvider>
                </DataProvider>
              </SignalNamesProvider>
            </TabConfigsProvider>
          </ActiveTabProvider>
        </LockProvider>
      ) : (
        <LandingPage
          backend={backend}
          setBackend={setBackend}
          backendUrls={backendUrls}
          backendError={backendError}
        />
      )}
    </MantineProvider>
  );
}

export default App;
