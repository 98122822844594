import { useEffect, useState } from "react";
import { Select } from "@mantine/core"

import { useApi } from "../contexts/ApiContext";

interface Props {
  backend: string | null;
  setBackend: React.Dispatch<React.SetStateAction<string | null>>;
  backendUrls: {[name: string]: string};
  backendError: boolean;
}

export default function LandingPage( {
  backend,
  setBackend,
  backendUrls,
  backendError
}: Props) {
  const { api, setApi } = useApi();
  return (
    <Select
      data={Object.keys(backendUrls)}
      value={backend}
      onChange={setBackend}
      placeholder="Select data location"
      styles={{wrapper: {width: 500, padding: 10}}}
      error={backendError}
    />
  )
}
