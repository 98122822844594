import React, { createContext, useContext, useState } from "react";

import { TabConfigs } from "../services/session/SessionClasses";

type TabConfigsState = {
  tabConfigs: TabConfigs;
  setTabConfigs: React.Dispatch<React.SetStateAction<TabConfigs>>;
};

const TabConfigsContext = createContext<TabConfigsState | undefined>(undefined);

interface Props {
  initialTabConfigs: TabConfigs;
  children: any;
}
export function TabConfigsProvider({ initialTabConfigs, children }: Props) {
  const [tabConfigs, setTabConfigs] = useState<TabConfigs>(initialTabConfigs);
  return (
    <TabConfigsContext.Provider value={{ tabConfigs, setTabConfigs }}>
      {children}
    </TabConfigsContext.Provider>
  );
}

export function useTabConfigs() {
  const tabConfigsState = useContext(TabConfigsContext);
  if (!tabConfigsState) {
    throw new Error("useTabConfigs must be inside the TabConfigsProvider");
  }
  return tabConfigsState;
}
