import { useState } from "react";
import { SegmentedControl } from "@mantine/core";

interface Props {
    sampleMode: string;
    setSampleMode: React.Dispatch<React.SetStateAction<string>>;
}

export default function SampleModeControl({ sampleMode, setSampleMode }: Props) {
    return <>
        <SegmentedControl
            value={sampleMode}
            onChange={setSampleMode}
            data={["Every Nth", "Average", "Min Max"]}
        />
    </>
}