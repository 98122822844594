import { Tabs as MantineTabs } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useTabConfigs } from "../../contexts/TabConfigContext";
import { useActiveTab } from "../../contexts/ActiveTabContext";
import _ from "lodash";

export default function AddTabButton() {
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { setActiveTab } = useActiveTab();

  const addTabConfig = () => {
    let newTabId = "";
    do {
      newTabId = _.uniqueId("Tab-");
    } while (Object.keys(tabConfigs).includes(newTabId));
    const newTabObj = { name: newTabId, plotConfigs: {}, layout: [] };
    if (!tabConfigs) {
      setTabConfigs({
        newTabId: { name: newTabId, plotConfigs: {}, layout: [] },
      });
    } else {
      tabConfigs[newTabId] = newTabObj;
      setTabConfigs({ ...tabConfigs });
    }
    setActiveTab(newTabId);
  };

  return (
    <MantineTabs.Tab
      value={"addTabButton"}
      key={"addTabButton"}
      styles={{
        tab: {
          height: 32,
          width: 32,
          borderRadius: 16,
          borderBottomWidth: 0,
        },
      }}
      leftSection={<IconPlus size={18} strokeWidth={2} />}
      onClick={() => {
        addTabConfig();
      }}
    ></MantineTabs.Tab>
  );
}
