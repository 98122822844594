import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

import { SampledData } from "../services/session/data/DataClasses";

type DataState = {
  data: SampledData;
  setData: React.Dispatch<React.SetStateAction<SampledData>>;
};

const DataContext = createContext<DataState | undefined>(undefined);

export function DataProvider({ children }: PropsWithChildren<{}>) {
  const [data, setData] = useState<SampledData>({});
  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
}

export function useData(): DataState {
  const dataState = useContext(DataContext);
  if (!dataState) {
    throw new Error("useData must be within DataProvider");
  }
  return dataState;
}
