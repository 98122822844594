import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

type XAxisRangeState = {
  xAxisRange: number[] | undefined;
  setXAxisRange: React.Dispatch<React.SetStateAction<number[] | undefined>>;
};

const XAxisRangeContext = createContext<XAxisRangeState | undefined>(undefined);

export function XAxisRangeProvider({ children }: PropsWithChildren<{}>) {
  const [xAxisRange, setXAxisRange] = useState<number[] | undefined>(undefined);
  return (
    <XAxisRangeContext.Provider value={{ xAxisRange, setXAxisRange }}>
      {children}
    </XAxisRangeContext.Provider>
  );
}

export function useXAxisRange(): XAxisRangeState {
  const xAxisRangeState = useContext(XAxisRangeContext);
  if (!xAxisRangeState) {
    throw new Error("useXAxisRange must be within XAxisRangeProvider");
  }
  return xAxisRangeState;
}
