import { useRef, useState } from "react";
import { Tabs } from "@mantine/core";

import { useTabConfigs } from "../../contexts/TabConfigContext";
import TabMenu from "./TabMenu";

interface Props {
  tabId: string;
}

export default function Tab({ tabId }: Props) {
  const { tabConfigs } = useTabConfigs();
  const [opened, setOpened] = useState(false);
  const [menuLeft, setMenuLeft] = useState(0);
  const [menuTop, setMenuTop] = useState(0);
  const tabRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        setMenuLeft(e.pageX);
        setMenuTop(e.pageY);
        setOpened(true);
      }}
    >
      <Tabs.Tab
        value={tabId}
        ref={tabRef}
        styles={{
          tab: {
            paddingLeft: 8,
            paddingRight: 8,
            height: 35,
          },
        }}
      >
        <TabMenu
          opened={opened}
          setOpened={setOpened}
          left={menuLeft}
          top={menuTop}
          tabId={tabId}
        />
        {tabConfigs[tabId].name}
      </Tabs.Tab>
    </div>
  );
}
