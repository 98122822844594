import { Button } from "@mantine/core";

import { useApi } from "../contexts/ApiContext";
import { useTabConfigs } from "../contexts/TabConfigContext";

export default function SaveButton() {
  const { api } = useApi();
  const { tabConfigs } = useTabConfigs();
  return (
    <Button
      onClick={() => {
        api.sessionApi.saveSession(tabConfigs);
      }}
    >
      Save
    </Button>
  );
}
