import {
  Menu,
  MultiSelect,
  Popover,
  Text,
  SegmentedControl,
  Button,
  Space,
} from "@mantine/core";
import React, { useState, useEffect } from "react";

import { useSignalNames } from "../../contexts/SignalNamesContext";
import { useTabConfigs } from "../../contexts/TabConfigContext";
import { useXAxisRange } from "../../contexts/XAxisRangeContext";
import handleSelections from "../../utils/HandleSelections";
import { useApi } from "../../contexts/ApiContext";
import { useData } from "../../contexts/DataContext";
import { useMinMaxXAllowed } from "../../contexts/MinMaxXAllowedContext";
import { useUiRevision } from "../../contexts/UiRevisionContext";

interface Props {
  yAxisNum: number;
  tabId: string;
  plotId: string;
  plotMenuOpened: boolean;
  localUiRevision: number;
  setLocalUiRevision: React.Dispatch<React.SetStateAction<number>>;
  yVars: { [sourceName: string]: string[] };
  focusedYAxis: number | undefined;
  setFocusedYAxis: React.Dispatch<React.SetStateAction<number | undefined>>;
  sampleMode: string;
}

export default function YAxisConfigPopover({
  yAxisNum,
  tabId,
  plotId,
  plotMenuOpened,
  localUiRevision,
  setLocalUiRevision,
  yVars,
  focusedYAxis,
  setFocusedYAxis,
  sampleMode
}: Props) {
  const colors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
  ];
  const { api } = useApi();
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { signalNames } = useSignalNames();
  const [opened, setOpened] = useState(false);
  const { xAxisRange, setXAxisRange } = useXAxisRange();
  const { minMaxXAllowed, setMinMaxXAllowed } = useMinMaxXAllowed();
  const { uiRevision, setUiRevision } = useUiRevision();
  const { data, setData } = useData();
  let colorIdx = 0;
  for (let i = 0; i < yAxisNum - 1; i++) {
    colorIdx += Object.values(
      tabConfigs[tabId].plotConfigs[plotId].yAxes[i].vars,
    ).flat().length;
  }
  useEffect(() => {
    if (focusedYAxis !== yAxisNum) {
      setOpened(false);
    }
  }, [focusedYAxis]);
  const handleDisabled = (group: string) => {
    const selectedXVar = tabConfigs[tabId].plotConfigs[plotId].xVar;
    if (selectedXVar) {
      if (
        signalNames.find(
          (signalsGroup) =>
            (signalsGroup.group === "common" || signalsGroup.group === group) &&
            signalsGroup.items.includes(selectedXVar),
        )
      ) {
        return false;
      }
    }
    return true;
  };
  const changeSideDisabled = tabConfigs[tabId].plotConfigs[plotId].yAxes.filter(
      ({axis})=> axis.side === "left"
  ).length > 2;
  return (
    <Popover
      position="right"
      withArrow
      opened={opened && plotMenuOpened}
      styles={{
        dropdown: { borderColor: colors[colorIdx] },
        arrow: { borderColor: colors[colorIdx] },
      }}
    >
      <Popover.Target>
        <Menu.Item
          key={`${yAxisNum}-item`}
          onClick={() => {
            if (!opened) {
              setFocusedYAxis(yAxisNum);
            }
            setOpened(!opened);
          }}
        >
          <Text ta="center" fw={500} c={colors[colorIdx]}>
            {`${opened ? "Close" : "Open"} Y${yAxisNum}`}
          </Text>
        </Menu.Item>
      </Popover.Target>
      <Popover.Dropdown>
        <SegmentedControl
            disabled={changeSideDisabled}
          data={["left", "right"]}
          fullWidth
          value={
            tabConfigs[tabId].plotConfigs[plotId].yAxes[yAxisNum - 1].axis.side
          }
          onChange={(value) => {
            tabConfigs[tabId].plotConfigs[plotId].yAxes[
              yAxisNum - 1
            ].axis.side = value;
            setTabConfigs({ ...tabConfigs });
          }}
        />
        <div>
          {signalNames.length > 0
            ? signalNames
                .filter(({ group }) => group !== "common")
                .map(({ group, items }) => {
                  const disabled = handleDisabled(group);
                  return (
                    <MultiSelect
                      key={`${tabId}-${plotId}-${yAxisNum}-${group}-YMultiSelect`}
                      style={{ width: 250 }}
                      error={disabled}
                      label={group}
                      clearable
                      searchable
                      disabled={disabled}
                      data={items}
                      value={yVars[group]}
                      onChange={(values) => {
                        yVars[group] = values;
                        tabConfigs[tabId].plotConfigs[plotId].yAxes[
                          yAxisNum - 1
                        ].vars = yVars;
                        const sourceSignals = handleSelections(
                          values,
                          group,
                          tabConfigs[tabId].plotConfigs,
                          data,
                        );
                        if (sourceSignals) {
                          if (xAxisRange) {
                            api.sessionApi.dataApi
                              .sliceAndSample(
                                sourceSignals,
                                xAxisRange[0],
                                xAxisRange[1],
                                sampleMode
                              )
                              .then((sampledData) => {
                                if (sampledData) {
                                  setData({ ...sampledData });
                                } else {
                                  setData({});
                                }
                              });
                          } else {
                            api.sessionApi.dataApi
                              .sliceAndSample(
                                sourceSignals,
                                null,
                                null,
                                sampleMode
                              )
                              .then((sampledData) => {
                                if (sampledData) {
                                  setData({ ...sampledData });
                                  setXAxisRange(minMaxXAllowed);
                                  setUiRevision(uiRevision + 1);
                                } else {
                                  setData({});
                                }
                              });
                          }
                        }
                        setLocalUiRevision(localUiRevision + 1);
                        setTabConfigs({ ...tabConfigs });
                      }}
                    />
                  );
                })
            : tabConfigs[tabId].plotConfigs[plotId].yAxes.map((yAxis) => {
                return Object.entries(yAxis.vars).map(([source, signals]) => {
                  return (
                    <MultiSelect
                      key={`${tabId}-${plotId}-${yAxisNum}-${source}-YMultiSelect`}
                      style={{ width: 250 }}
                      label={source}
                      clearable
                      searchable
                      value={signals}
                      onChange={(values) => {
                        yVars[source] = values;
                        tabConfigs[tabId].plotConfigs[plotId].yAxes[
                          yAxisNum - 1
                        ].vars = yVars;
                        setTabConfigs({ ...tabConfigs });
                      }}
                    />
                  );
                });
              })}
          <Space h="sm" />
          <Button
            fullWidth
            variant="outline"
            color="teal"
            onClick={() => {
              tabConfigs[tabId].plotConfigs[plotId].yAxes[
                yAxisNum - 1
              ].axis.autoscale = true;
              tabConfigs[tabId].plotConfigs[plotId].yAxes[
                yAxisNum - 1
              ].axis.range = undefined;
              setLocalUiRevision(localUiRevision + 1);
              setTabConfigs({ ...tabConfigs });
            }}
          >
            Reset Scale
          </Button>
          <Space h="sm" />
          <Button
            fullWidth
            variant="outline"
            color="red"
            onClick={() => {
              tabConfigs[tabId].plotConfigs[plotId].yAxes.splice(
                yAxisNum - 1,
                1,
              );
              setOpened(false);
              setTabConfigs({ ...tabConfigs });
            }}
          >
            Delete Axis
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
