import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type LockState = {
  locked: boolean;
  setLocked: React.Dispatch<React.SetStateAction<boolean>>;
};

const LockContext = createContext<LockState | undefined>(undefined);

export function LockProvider({ children }: PropsWithChildren<{}>) {
  const [locked, setLocked] = useState(true);
  return (
    <LockContext.Provider value={{ locked, setLocked }}>
      {children}
    </LockContext.Provider>
  );
}

export function useLock(): LockState {
  const lockState = useContext(LockContext);
  if (!lockState) {
    throw new Error("useLock must be inside LockProvider");
  }
  return lockState;
}
