import { Button } from "@mantine/core";
import { useUiRevision } from "../contexts/UiRevisionContext";
import { useActiveTab } from "../contexts/ActiveTabContext";
import { useTabConfigs } from "../contexts/TabConfigContext";

export default function ResetYScaleButton() {
  const { uiRevision, setUiRevision } = useUiRevision();
  const { activeTab } = useActiveTab();
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  return (
    <>
      <Button
        style={{ pos: "top", paddingRight: 5, paddingLeft: 5 }}
        size="sm"
        onClick={() => {
          if (activeTab) {
            Object.keys(tabConfigs[activeTab].plotConfigs).forEach((plotId) => {
              tabConfigs[activeTab].plotConfigs[plotId].yAxes.forEach(({axis}, idx) => {
                axis.autoscale = true
                axis.range = undefined
                tabConfigs[activeTab].plotConfigs[plotId].yAxes[idx].axis = axis
              })
            })
            setUiRevision(uiRevision + 1);
            setTabConfigs(tabConfigs)
          }
        }}
      >
        Reset Y Scale
      </Button>
    </>
  );
}