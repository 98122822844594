import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type FileDirState = {
  fileDir: string;
  setFileDir: React.Dispatch<React.SetStateAction<string>>;
};

const FileDirectoryContext = createContext<FileDirState | undefined>(undefined);

export function FileDirectoryProvider({ children }: PropsWithChildren<{}>) {
  const [fileDir, setFileDir] = useState("");
  return (
    <FileDirectoryContext.Provider value={{ fileDir, setFileDir }}>
      {children}
    </FileDirectoryContext.Provider>
  );
}

export function useFileDirectory(): FileDirState {
  const fileDirState = useContext(FileDirectoryContext);
  if (!fileDirState) {
    throw new Error("useFileDirectory must be inside LockProvider");
  }
  return fileDirState;
}
