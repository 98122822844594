import { default as PlotlyPlot } from "react-plotly.js";
import { Figure } from "react-plotly.js";
import React, { useEffect, useState, useRef } from "react";
import NanoDate from "nano-date";

import { useLock } from "../../contexts/LockContext";
import { useTabConfigs } from "../../contexts/TabConfigContext";
import { useData } from "../../contexts/DataContext";
import { useXAxisRange } from "../../contexts/XAxisRangeContext";
import { useUiRevision } from "../../contexts/UiRevisionContext";
import { useFaultData } from "../../contexts/FaultDataContext";
import { useFaultSelections } from "../../contexts/FaultSelectionsContext";

interface Props {
  plotTitle: string;
  plotId: string;
  tabId: string;
  height: number;
  width: number;
  plotData: Object[];
  plotType: "line" | "scatter";
  localUiRevision: number;
  setLocalUiRevision: React.Dispatch<React.SetStateAction<number>>;
  setMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  focusedPlot: string;
  setFocusedPlot: React.Dispatch<React.SetStateAction<string>>;
}

export default function Plot({
  plotTitle,
  plotId,
  tabId,
  height,
  width,
  plotType,
  localUiRevision,
  setLocalUiRevision,
  setMenuOpened,
  focusedPlot,
  setFocusedPlot,
}: Props) {
  const colors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
  ];
  const { locked } = useLock();
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { data } = useData();
  const { faultData } = useFaultData();
  const { faultSelections } = useFaultSelections();
  const { xAxisRange, setXAxisRange } = useXAxisRange();
  const { uiRevision } = useUiRevision();
  const ref = useRef(null);
  const [dateTimeTickVals, setDateTimeTickVals] = useState<
    Number[] | undefined
  >();
  const [dateTimeTickText, setDateTimeTickText] = useState<
    string[] | undefined
  >();
  useEffect(() => {
    if (
      data.hasOwnProperty("one_sec") &&
      data["one_sec"].hasOwnProperty("vcm_date_time")
    ) {
      setDateTimeTickText(
        //@ts-ignore
        Array.from(data["one_sec"]["vcm_date_time"]).map((epoch) => {
          const dateTime = new Date(epoch * 1000);
          return dateTime.toLocaleTimeString();
        }),
      );
      //@ts-ignore
      setDateTimeTickVals([...data["one_sec"]["tick_count"]]);
    }
  }, [data]);
  useEffect(() => {
    if (plotId !== focusedPlot) {
      setLocalUiRevision(localUiRevision + 1);
    }
  }, [xAxisRange]);
  let layout: {} = {
    autosize: true,
    showlegend: true,
    uirevision: uiRevision + localUiRevision,
    title: {
      text: plotTitle,
      yanchor: "middle",
      font: { size: 12 },
      y: 0.95,
    },
    margin: { t: 70, b: 50, l: 60, r: 60 },
    width: width,
    height: height,
    xaxis: {
      title: {
        text: tabConfigs[tabId].plotConfigs[plotId].xVar,
        standoff: 8,
      },
      linecolor: "white",
      zeroline: false,
      autorange: plotType === "scatter",
      range: plotType === "line" ? xAxisRange : undefined,
      showticklabels: true,
      showgrid: false,
    },
    paper_bgcolor: "black",
    plot_bgcolor: "black",
    font: { color: "white", size: 10 },
    legend: {
      font: { size: 12, color: "white" },
      orientation: "h",
      y: -50 / (height * 1.3),
    },
  };
  if (
    tabConfigs[tabId].plotConfigs[plotId].xVar === "tick_count" &&
    dateTimeTickVals &&
    plotType !== "scatter"
  ) {
    //@ts-ignore
    layout["xaxis2"] = {
      // autorange: true,
      autorange: false,
      fixedrange: true,
      range: dateTimeTickVals
        ? [dateTimeTickVals[0], dateTimeTickVals[dateTimeTickVals.length - 1]]
        : undefined,
      overlaying: "x",
      side: "top",
      type: "date",
      tickmode: "array",
      showticklabels: true,
      tickformat: "%Y-%m-%dT%H:%M:%S.%L",
      linecolor: "white",
      zeroline: false,
      showgrid: true,
      visible: true,
      tickvals: dateTimeTickVals ? [...dateTimeTickVals] : undefined,
      ticktext: dateTimeTickText ? [...dateTimeTickText] : undefined,
    };
  }
  let domainMin = 0;
  const plotData: {}[] = [];
  let baseYAxis = 1;
  if (Object.values(faultSelections).flat().length > 0 && plotType === "line") {
    domainMin = 0.05;
    baseYAxis = 2;
    let faultColorIdx = 0;
    // @ts-ignore
    layout["yaxis"] = {
      nticks: 1,
      rangemode: "fixed",
      range: [0.5, 1.5],
      domain: [0, domainMin],
    };
    for (const [status, signals] of Object.entries(faultSelections)) {
      for (const signal of signals) {
        if (colors.length - 1 - faultColorIdx === -1) {
          faultColorIdx = 0;
        }
        faultColorIdx++;
        plotData.push({
          x: faultData[status][signal],
          y: new Array(faultData[status][signal].length).fill(1),
          yaxis: "y",
          type: "scattergl",
          mode: "markers",
          name: `${status}-${signal}`,
          legendrank: 2,
          marker: {
            size: 6,
            line: {
              color: "white",
              width: 1,
            },
            color: colors[colors.length - 1 - faultColorIdx],
          },
        });
      }
    }
  }
  const leftCount = tabConfigs[tabId].plotConfigs[plotId].yAxes.filter(
    ({ axis }) => axis.side === "left",
  ).length;
  const rightCount = tabConfigs[tabId].plotConfigs[plotId].yAxes.filter(
    ({ axis }) => axis.side === "right",
  ).length;
  let colorIdx = 0;
  const leftDomainDiff = (1 - domainMin) / leftCount;
  const rightDomainDiff = (1 - domainMin) / rightCount;
  let leftAxisIdx = 0;
  let rightAxisIdx = 0;

  plotData.push(
    ...tabConfigs[tabId].plotConfigs[plotId].yAxes.reduce(
      (accumulator: {}[], { vars, axis }, i) => {
        let domain = [];
        if (axis.side === "left") {
          domain = [
            domainMin + leftAxisIdx * leftDomainDiff,
            (leftAxisIdx + 1) * leftDomainDiff + domainMin,
          ];
          leftAxisIdx++;
        } else {
          domain = [
            domainMin + rightAxisIdx * rightDomainDiff,
            (rightAxisIdx + 1) * rightDomainDiff,
          ];
          rightAxisIdx++;
        }
        let overlaying = undefined;
        const rightAxis =
          tabConfigs[tabId].plotConfigs[plotId].yAxes.filter(
            ({ axis }) => axis.side === "right",
          ).length > 0;
        if (rightAxis) {
          if (baseYAxis === 1) {
            if (i !== 0) {
              overlaying = "y";
            }
          } else {
            if (i !== 0) {
              overlaying = "y2";
            }
          }
        }
        const { side, range, autoscale } = { ...axis };
        const yAxisConfig = {
          side: side,
          range: range,
          autoscale: autoscale,
          ticklen: 5,
          tickangle: -45,
          linecolor: colors[colorIdx],
          zeroline: false,
          overlaying: overlaying,
          tickfont: { size: 12, color: colors[colorIdx] },
          showgrid: true,
          gridcolor: "#696969",
          domain: domain,
        };

        // @ts-ignore
        layout[`yaxis${baseYAxis + i}`] = yAxisConfig;

        const xVar = tabConfigs[tabId].plotConfigs[plotId].xVar;
        for (const [source, signals] of Object.entries(vars)) {
          if (
            xVar &&
            data.hasOwnProperty(source) &&
            data[source].hasOwnProperty(xVar)
          ) {
            const xData = data[source][xVar];
            for (const signal of signals) {
              if (data[source].hasOwnProperty(signal)) {
                if (
                  data[source][signal].hasOwnProperty("min") &&
                  data[source][signal].hasOwnProperty("max")
                ) {
                  accumulator.push({
                    x: xData,
                    //@ts-ignore
                    y: data[source][signal].min,
                    type: plotType === "scatter" ? "scattergl" : "scatter",
                    yaxis: `y${baseYAxis + i}`,
                    mode: plotType === "scatter" ? "markers" : "lines",
                    name: signal,
                    line: { color: colors[colorIdx] },
                    legendgroup: signal,
                    legendrank: 1,
                  });
                  accumulator.push({
                    x: xData,
                    //@ts-ignore
                    y: data[source][signal].max,
                    type: plotType === "scatter" ? "scattergl" : "scatter",
                    yaxis: `y${baseYAxis + i}`,
                    mode: plotType === "scatter" ? "markers" : "lines",
                    name: signal,
                    line: { color: colors[colorIdx] },
                    legendgroup: signal,
                    showlegend: false,
                  });
                } else {
                  accumulator.push({
                    x: xData,
                    y: data[source][signal],
                    type: plotType === "scatter" ? "scattergl" : "scatter",
                    yaxis: `y${baseYAxis + i}`,
                    mode: plotType === "scatter" ? "markers" : "lines",
                    name: signal,
                    line: { color: colors[colorIdx] },
                    legendgroup: signal,
                    legendrank: 1,
                  });
                }
              }
              colorIdx++;
              if (colorIdx === colors.length) {
                colorIdx = 0;
              }
            }
          }
        }
        return accumulator;
      },
      [],
    ),
  );
  if (plotType === "line") {
    plotData.push({
      x: [],
      y: [],
      xaxis: "x2",
    });
  }
  return (
    <>
      <PlotlyPlot
        ref={ref}
        onUpdate={(fig) => {
          if (plotData.length > 0) {
            let yAxis = "yaxis";
            let idx = 0;
            for (const { axis } of tabConfigs[tabId].plotConfigs[plotId]
              .yAxes) {
              // Could create mapping from axis to axis id
              axis.autoscale = false;
              if (baseYAxis === 2 || (baseYAxis === 1 && idx > 0)) {
                yAxis = `yaxis${baseYAxis + idx}`;
              }
              //@ts-ignore
              if (ref.current.el.layout.hasOwnProperty(yAxis)) {
                //@ts-ignore
                if (axis.range !== ref.current.el.layout[yAxis].range) {
                  //@ts-ignore
                  axis.range = ref.current.el.layout[yAxis].range;
                }
              }
              //@ts-ignore
              tabConfigs[tabId].plotConfigs[plotId].yAxes[idx].axis = axis;
              idx++;
            }
            setTabConfigs(tabConfigs);
          }
        }}
        onRelayout={(e) => {
          setFocusedPlot(plotId);
          if (plotType !== "scatter") {
            const [xMinKey, xMaxKey] = ["xaxis.range[0]", "xaxis.range[1]"];
            if (e.hasOwnProperty(xMinKey) && e.hasOwnProperty(xMaxKey)) {
              // @ts-ignore
              setXAxisRange([...[e[xMinKey], e[xMaxKey]]]);
            }
          }
        }}
        onClick={(e) => {
          if (e.event.button === 0) {
            setMenuOpened(false);
          }
        }}
        data={plotData}
        config={{
          responsive: false,
          autosizable: false,
          staticPlot: plotType === "line" ? !locked : true,
          showTips: false,
          scrollZoom: true,
          doubleClick: false,
          displaylogo: false,
          modeBarButtonsToRemove: [
            "zoomIn2d",
            "autoScale2d",
            "zoom2d",
            "zoomOut2d",
            "resetScale2d",
            "lasso2d",
            "select2d",
          ],
        }}
        layout={layout}
      />
    </>
  );
}
