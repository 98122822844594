import axios, { AxiosResponse, AxiosInstance, Axios } from "axios";
import { notifications } from "@mantine/notifications";

import { SessionMeta, TabConfigs } from "./SessionClasses";
import DataApi from "./data/DataApi";
import FileApi from "./file/FileApi";

class SessionApi {
  readonly pathPrefix = "/session";
  private sessionId: string | null;
  private apiClient: AxiosInstance;
  // private getSessionController = new AbortController();
  private getSessionController: null | AbortController = null;
  private saveSessionController: null | AbortController = null;
  public dataApi: DataApi;
  public fileApi: FileApi;
  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
    this.sessionId = localStorage.getItem("sessionId");
    this.apiClient.defaults.baseURL += this.pathPrefix;
    Object.assign(this.apiClient.defaults.headers, {
      sessionId: this.sessionId,
    });
    this.dataApi = new DataApi(this.apiClient);
    this.fileApi = new FileApi(this.apiClient);
  }

  async getSession(): Promise<SessionMeta | undefined> {
    /**
     * Get an existing session metadata or create a new session depending on whether a
     * sessionId is passed in the request headers
     *
     * @returns Session metadata for a new or existing session
     */
    if (this.getSessionController !== null) {
      this.getSessionController.abort();
    }
    this.getSessionController = new AbortController();
    try {
      const response: AxiosResponse<SessionMeta> =
        await this.apiClient.get(
          "/",
          { 
            signal: this.getSessionController.signal,
            params: {
              t: new Date().getTime()
            }
          }
        );
      localStorage.setItem("sessionId", response.data.id);
      return response.data;
    } catch (err) {
      notifications.show({
        title: `Error with api request to get session`,
        message: `${err}`,
        color: "red",
        withCloseButton: true
      })
    } finally {
      this.getSessionController = null;
    }
  }
  async saveSession(tabConfigs: TabConfigs): Promise<void> {
    /**
     * Save the current state of a session
     *
     * @returns Nothing
     */
    if (this.saveSessionController !== null) {
      this.saveSessionController.abort();
    }
    this.saveSessionController = new AbortController();
    try {
      const response: AxiosResponse<void> = await this.apiClient.put(
        "/",
        tabConfigs,
        { 
          params: { t: new Date().getTime()}, 
          signal: this.saveSessionController.signal
        },
      );
      return response.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.saveSessionController = null;
    }
  }
}

export default SessionApi;
