import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

type ActiveTabState = {
  activeTab: string | null;
  setActiveTab: React.Dispatch<React.SetStateAction<string | null>>;
};

const ActiveTabContext = createContext<ActiveTabState | undefined>(undefined);

export function ActiveTabProvider({ children }: PropsWithChildren<{}>) {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  );
}

export function useActiveTab(): ActiveTabState {
  const activeTabState = useContext(ActiveTabContext);
  if (!activeTabState) {
    throw new Error("useActiveTab must be within ActiveTabProvider");
  }
  return activeTabState;
}
