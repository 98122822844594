import { Popover, Text, Button } from "@mantine/core";
import React, { useState } from "react";

import { useApi } from "../contexts/ApiContext";

interface Props {
  binFiles: string[];
  selectedBzFile: string | null;
  setBinFiles: React.Dispatch<React.SetStateAction<string[]>>;
}
export default function ExtractFileButton({
  binFiles,
  selectedBzFile,
  setBinFiles,
}: Props) {
  const { api } = useApi();
  const [loading, setLoading] = useState(false);
  const handleExtract = () => {
    if (selectedBzFile) {
      setLoading(true);
      api.sessionApi.fileApi
        .extract(selectedBzFile)
        .then((res) => {
          setBinFiles(res ? res : []);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };
  return (
    <div>
      {binFiles.length > 0 ? (
        <Popover position="bottom">
          <Popover.Target>
            <Button loading={loading}>Extract</Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Text size="sm" style={{ paddingBottom: 10 }}>
              Bin files exist from this bz file, do you wish to extract again?
            </Text>
            <Button onClick={handleExtract} loading={loading}>
              Confirm
            </Button>
          </Popover.Dropdown>
        </Popover>
      ) : (
        <Button loading={loading} onClick={handleExtract}>
          Extract
        </Button>
      )}
    </div>
  );
}
