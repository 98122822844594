import { Modal } from "@mantine/core";

import DirectoryTreeView from "./DirectoryTreeView";
import React from "react";

interface Props {
  opened: boolean;
  close: () => void;
  setBzFiles: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedBz: React.Dispatch<React.SetStateAction<string | null>>;
  bzDropdownOpenTrig: boolean;
}

export default function FileTreeModal({
  opened,
  close,
  setBzFiles,
  setSelectedBz,
  bzDropdownOpenTrig,
}: Props) {
  return (
    <>
      <Modal
        keepMounted={true}
        title="Select a directory"
        opened={opened}
        onClose={close}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            close();
          }
        }}
        withCloseButton={false}
      >
        <DirectoryTreeView
          setBzFiles={setBzFiles}
          setSelectedBz={setSelectedBz}
          bzDropdownOpenTrig={bzDropdownOpenTrig}
        />
      </Modal>
    </>
  );
}
