import { Button } from "@mantine/core";
import { useState } from "react";

import { SessionMeta } from "../services/session/SessionClasses";
import { useApi } from "../contexts/ApiContext";

interface Props {
    setSessionMeta: React.Dispatch<React.SetStateAction<SessionMeta | undefined>>;
    backend: string | null;
    setBackend: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function LandingPageButton( {setSessionMeta, setBackend, backend}: Props ) {
    const { setApi } = useApi();
    const [ text, setText ] = useState<string>(backend ? backend : "Unknown Backend");
    return (
        <Button
            onClick={() => {
                setSessionMeta(undefined);
                setBackend(null);
            }}
            onMouseOver={ () => setText("Change") }
            onMouseOut={ () => {setText(backend ? backend : "Unknown Backend")} }
        >
            {text}
        </Button>
    );
}