import { AxiosResponse, AxiosInstance } from "axios";
import pako from "pako";
import { BSON } from "bson";

import { FaultData, SourceSignals, TypedArrayConstructor } from "./DataClasses";
import { SampledData } from "./DataClasses";

class DataApi {
  readonly typeMapping: { [numpyType: string]: TypedArrayConstructor } = {
    uint8: Uint8Array,
    uint16: Uint16Array,
    uint32: Uint32Array,
    int16: Int16Array,
    int32: Int32Array,
    float32: Float32Array,
    double: Float64Array,
    float64: Float64Array,
  };
  readonly pathPrefix = "/data";
  private apiClient: AxiosInstance;
  private sliceAndSampleController: null | AbortController = null;
  private faultsController: null | AbortController = null;
  constructor(apiClient: AxiosInstance) {
    this.apiClient = apiClient;
  }

  private decompressData(gzippedBson: ArrayBuffer): SampledData {
    const bsonData = pako.ungzip(gzippedBson);
    const data = BSON.deserialize(bsonData);
    for (const source in data) {
      for (const signal in data[source]) {
        const { vector, type } = data[source][signal];
        if (signal !== "tick_count") {
          if (vector.min) {
            data[source][signal] = {
              min: new this.typeMapping[type.min](vector.min.buffer.buffer),
              max: new this.typeMapping[type.max](vector.max.buffer.buffer),
            };
          } else {
            data[source][signal] = new this.typeMapping[type](
              vector.buffer.buffer,
            );
          }
        } else {
          data[source][signal] = new this.typeMapping[type](
            vector.buffer.buffer,
          );
        }
      }
    }
    return data;
  }
  async export(
    sourceSignals: SourceSignals,
    xMin: number | null,
    xMax: number | null,
    sampleMode: string,
    fileType: string,
    fileName: string,
    sample: undefined | string | number,
  ): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> = await this.apiClient.post(
        `${this.pathPrefix}/export`,
        {
          sourceSignals: sourceSignals,
          xMin: xMin,
          xMax: xMax,
          sampleMode: sampleMode,
          fileType: fileType,
          fileName: fileName,
          step: sample,
        },
        {},
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  async alignView(xMin: number | null, xMax: number | null): Promise<void> {
    try {
      const response: AxiosResponse<Blob> = await this.apiClient.get(
        `${this.pathPrefix}/align-view`,
        {
          params: {
            xMin: xMin,
            xMax: xMax,
          },
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "align_view.png");
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
    }
  }
  async sliceAndSample(
    sourceSignals: SourceSignals,
    xMin: number | null,
    xMax: number | null,
    sampleMode: string,
  ): Promise<SampledData | undefined> {
    /**
     * Query sampled data between xMin and xMax
     *
     * @param sourceSignals - The signals and corresponding sources to get data for,
     * i.e. "ia", "ib" from "icm" and "Torque" from "vcm"
     * @param xMin - Start x value for slicing the data
     * @param xMax - End x value for slicing the data
     * @param sampleMode - Every Nth, Average or Min Max
     *
     * @returns Subsampled vectors containing data between the given xMin and xMax for the sourceSignals
     */
    if (this.sliceAndSampleController !== null) {
      this.sliceAndSampleController.abort();
    }
    this.sliceAndSampleController = new AbortController();
    try {
      const response: AxiosResponse<ArrayBuffer | undefined> =
        await this.apiClient.get(`${this.pathPrefix}/slice-and-subsample`, {
          params: {
            sourceSignals: JSON.stringify(sourceSignals),
            xMin: xMin,
            xMax: xMax,
            mode: sampleMode,
            t: new Date().getTime(),
          },
          responseType: "arraybuffer",
          headers: {
            "Content-Encoding": "gzip",
            "Content-Type": "application/bson",
          },
          signal: this.sliceAndSampleController.signal,
        });
      if (!response.data) {
        return;
      }
      return this.decompressData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.sliceAndSampleController = null;
    }
  }
  async faults(): Promise<FaultData | undefined> {
    /**
     * Query fault trips from data
     *
     * @returns fault trip locations by tick
     */
    if (this.faultsController !== null) {
      this.faultsController.abort();
    }
    this.faultsController = new AbortController();
    try {
      const response: AxiosResponse<ArrayBuffer | undefined> =
        await this.apiClient.get(`${this.pathPrefix}/faults`, {
          params: {
            t: new Date().getTime(),
          },
          responseType: "arraybuffer",
          headers: {
            "Content-Encoding": "gzip",
            "Content-Type": "application/bson",
          },
          signal: this.faultsController.signal,
        });
      if (!response.data) {
        return;
      }
      const bsonData = pako.ungzip(response.data);
      const data = BSON.deserialize(bsonData);
      for (const status in data) {
        for (const fault in data[status]) {
          data[status][fault] = new Uint32Array(
            data[status][fault].buffer.buffer,
          );
        }
      }
      return data;
    } catch (err) {
      console.log(err);
    } finally {
      this.faultsController = null;
    }
  }
}

export default DataApi;
