import { Button } from "@mantine/core";

import { useApi } from "../contexts/ApiContext";
import { useTabConfigs } from "../contexts/TabConfigContext";
import { useActiveTab } from "../contexts/ActiveTabContext";
import { useXAxisRange } from "../contexts/XAxisRangeContext";
import { useState } from "react";

export default function AlignViewButton() {
  const { api } = useApi();
  const { activeTab } = useActiveTab();
  const { xAxisRange } = useXAxisRange();
  const [loading, setLoading] = useState(false);
  return (
    <Button
      loading={loading}
      onClick={() => {
        if (activeTab && xAxisRange) {
          setLoading(true);
          api.sessionApi.dataApi
            .alignView(xAxisRange[0], xAxisRange[1])
            .then((res) => {
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      }}
    >
      Align View
    </Button>
  );
}
