import React from "react";
import { Select } from "@mantine/core";

interface Props {
  files: string[];
  selectedBinFile: string | null;
  setSelectedBinFile: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function BinFileSelect({
  files,
  selectedBinFile,
  setSelectedBinFile,
}: Props) {
  return (
    <>
      <Select
        searchable
        data={files}
        value={selectedBinFile}
        onChange={(value) => {
          if (value !== selectedBinFile) {
            setSelectedBinFile(value);
          }
        }}
        placeholder="Select .bin File"
      ></Select>
    </>
  );
}
