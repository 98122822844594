import { Tabs as MantineTabs } from "@mantine/core";

import { useTabConfigs } from "../../contexts/TabConfigContext";
import { useActiveTab } from "../../contexts/ActiveTabContext";
import Tab from "./Tab";
import PlotGrid from "../PlotGrid/PlotGrid";
import AddTabButton from "./AddTabButton";
import React, { useEffect } from "react";
import handleSelections from "../../utils/HandleSelections";
import { useApi } from "../../contexts/ApiContext";
import { useData } from "../../contexts/DataContext";
import { useXAxisRange } from "../../contexts/XAxisRangeContext";
import { useUiRevision } from "../../contexts/UiRevisionContext";
import { useMinMaxXAllowed } from "../../contexts/MinMaxXAllowedContext";
import { useFileDirectory } from "../../contexts/FileDirectoryContext";
import { useSignalNames } from "../../contexts/SignalNamesContext";
import { useFaultData } from "../../contexts/FaultDataContext";
import { useFaultSelections } from "../../contexts/FaultSelectionsContext";

interface Props {
  selectedBinFile: string | null;
  setSelectedBinFile: React.Dispatch<React.SetStateAction<string | null>>;
  sampleMode: string;
}

export default function Tabs({ selectedBinFile, setSelectedBinFile, sampleMode}: Props) {
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { activeTab, setActiveTab } = useActiveTab();
  let tabPanels: React.ReactElement[] = [];
  const { data, setData } = useData();
  const { xAxisRange, setXAxisRange } = useXAxisRange();
  const { uiRevision, setUiRevision } = useUiRevision();
  const { minMaxXAllowed, setMinMaxXAllowed } = useMinMaxXAllowed();
  const { fileDir } = useFileDirectory();
  const { setSignalNames } = useSignalNames();
  const { setFaultData } = useFaultData();
  const { setFaultSelections } = useFaultSelections();
  const { api } = useApi();
  useEffect(() => {
    if (xAxisRange && activeTab && selectedBinFile) {
      const sourceSignals = handleSelections(
        undefined,
        undefined,
        tabConfigs[activeTab].plotConfigs,
        data,
      );
      if (sourceSignals) {
        api.sessionApi.dataApi
          .sliceAndSample(
            sourceSignals,
            xAxisRange[0],
            xAxisRange[1],
            sampleMode
          )
          .then((sampledData) => {
            if (sampledData) {
              setData({ ...sampledData });
            } else {
              setData({});
            }
            if (
              minMaxXAllowed &&
              xAxisRange[0] === minMaxXAllowed[0] &&
              xAxisRange[1] === minMaxXAllowed[1]
            ) {
              setUiRevision(uiRevision + 1);
            }
          });
      }
    }
  }, [xAxisRange, activeTab, sampleMode]);

  useEffect(() => {
    if (selectedBinFile) {
      api.sessionApi.fileApi
        .setBin(selectedBinFile)
        .then((xBoundaries) => {
          if (xBoundaries) {
            setMinMaxXAllowed(xBoundaries);
          }
          api.sessionApi.fileApi
            .signalNames(selectedBinFile)
            .then((signalNames) => {
              if (signalNames) {
                setSignalNames([...signalNames]);
              } else {
                setSignalNames([]);
              }
              if (activeTab) {
                const sourceSignals = handleSelections(
                  undefined,
                  undefined,
                  tabConfigs[activeTab].plotConfigs,
                  data,
                );
                if (sourceSignals) {
                  api.sessionApi.dataApi
                    .sliceAndSample(
                      sourceSignals,
                      null,
                      null,
                      sampleMode
                    )
                    .then((sampledData) => {
                      if (sampledData) {
                        setData({ ...sampledData });
                      } else {
                        setData({});
                      }
                    });
                }
              }
              api.sessionApi.dataApi
                .faults()
                .then((res) => {
                  if (res) {
                    setFaultData(res);
                  } else {
                    setFaultData({});
                  }
                })
                .catch(() => {
                  setFaultData({});
                });
            });
        }).catch(() => {
          setSelectedBinFile(null)
          setData({})
        });
    } else {
      setFaultData({});
      setFaultSelections({});
      setData({})
    }
  }, [selectedBinFile]);

  useEffect(() => {
    if (minMaxXAllowed) {
      setXAxisRange([...minMaxXAllowed]);
    }
  }, [minMaxXAllowed]);
  return (
    <>
      {tabConfigs ? (
        <MantineTabs
          value={activeTab}
          keepMounted={false}
          styles={{
            root: { paddingLeft: 5, paddingRight: 5 },
            list: { height: 35 },
          }}
          onChange={(tabId) => {
            setActiveTab(tabId);
          }}
        >
          <MantineTabs.List>
            {Object.keys(tabConfigs)
              .map((tabId) => {
                tabPanels.push(
                  <MantineTabs.Panel value={tabId} key={tabId + "-tabPanel"}>
                    <PlotGrid sampleMode={sampleMode}/>
                  </MantineTabs.Panel>,
                );
                return <Tab tabId={tabId} key={tabId + "-tab"}/>;
              })
              .concat(<AddTabButton key={"addPlotButton"} />)}
          </MantineTabs.List>
          {tabPanels}
        </MantineTabs>
      ) : (
        <AddTabButton />
      )}
    </>
  );
}
