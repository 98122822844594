import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from "react";

import { FaultData } from "../services/session/data/DataClasses";

type FaultDataState = {
  faultData: FaultData;
  setFaultData: React.Dispatch<React.SetStateAction<FaultData>>;
};

const FaultDataContext = createContext<FaultDataState | undefined>(undefined);

export function FaultDataProvider({ children }: PropsWithChildren<{}>) {
  const [faultData, setFaultData] = useState<FaultData>({});
  return (
    <FaultDataContext.Provider value={{ faultData, setFaultData }}>
      {children}
    </FaultDataContext.Provider>
  );
}

export function useFaultData(): FaultDataState {
  const faultDataState = useContext(FaultDataContext);
  if (!faultDataState) {
    throw new Error("useFaultData must be within FaultDataProvider");
  }
  return faultDataState;
}
