import { useDisclosure } from "@mantine/hooks";
import { Button, Modal, Loader, Checkbox, Table } from "@mantine/core";
import { useState, useEffect } from "react";

import { useFaultData } from "../contexts/FaultDataContext";
import { useFaultSelections } from "../contexts/FaultSelectionsContext";

export default function FaultsButton() {
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(true);
  const { faultSelections, setFaultSelections } = useFaultSelections();
  const { faultData } = useFaultData();
  useEffect(() => {
    if (Object.keys(faultData).length > 0) {
      setLoading(false);
      let tmpSelections: { [status: string]: string[] } = {};
      for (const status of Object.keys(faultData)) {
        tmpSelections[status] = [];
      }
      setFaultSelections({ ...tmpSelections });
    } else {
      setLoading(true);
      setFaultSelections({});
    }
  }, [faultData]);
  return (
    <>
      <Modal opened={opened} onClose={close} title="Faults" keepMounted={true}>
        {faultData ? (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th />
                <Table.Th>Group</Table.Th>
                <Table.Th>Fault</Table.Th>
                <Table.Th>No. trips</Table.Th>
              </Table.Tr>
            </Table.Thead>
            {
              <Table.Tbody>
                {Object.keys(faultData).map((status) => {
                  return Object.keys(faultData[status]).map((signalName) => {
                    const trips = faultData[status][signalName].length;
                    if (trips > 0) {
                      return (
                        <Table.Tr
                          key={`${status}-${signalName}`}
                          bg={
                            faultSelections.hasOwnProperty(status) &&
                            faultSelections[status].includes(signalName)
                              ? "var(--mantine-color-blue-light)"
                              : undefined
                          }
                        >
                          <Table.Td>
                            <Checkbox
                              checked={
                                faultSelections.hasOwnProperty(status) &&
                                faultSelections[status].includes(signalName)
                              }
                              onChange={(e) => {
                                if (faultSelections.hasOwnProperty(status)) {
                                  if (
                                    e.currentTarget.checked &&
                                    !faultSelections[status].includes(
                                      signalName,
                                    )
                                  ) {
                                    faultSelections[status].push(signalName);
                                    setFaultSelections({ ...faultSelections });
                                  } else {
                                    if (
                                      faultSelections[status].includes(
                                        signalName,
                                      )
                                    ) {
                                      let newSelections = faultSelections;
                                      newSelections[status] = newSelections[
                                        status
                                      ].filter(
                                        (element) => element !== signalName,
                                      );
                                      setFaultSelections({ ...newSelections });
                                    }
                                  }
                                }
                              }}
                            ></Checkbox>
                          </Table.Td>
                          <Table.Td>{status}</Table.Td>
                          <Table.Td>{signalName}</Table.Td>
                          <Table.Td>{trips}</Table.Td>
                        </Table.Tr>
                      );
                    }
                  });
                })}
              </Table.Tbody>
            }
          </Table>
        ) : (
          <Loader />
        )}
      </Modal>
      <Button
        onClick={open}
        style={{ pos: "top" }}
        size="sm"
        color={"red"}
        loading={loading}
      >
        Faults
      </Button>
    </>
  );
}
