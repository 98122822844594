import React, { useRef, useState } from "react";
import GridLayout, { Layout } from "react-grid-layout";

import { useTabConfigs } from "../../contexts/TabConfigContext";
import { useLock } from "../../contexts/LockContext";
import { useActiveTab } from "../../contexts/ActiveTabContext";
import { PlotWrapper } from "./PlotWrapper";
import { useUiRevision } from "../../contexts/UiRevisionContext";

interface Props {
  sampleMode: string;
}

export default function PlotGrid({ sampleMode }: Props) {
  const width = window.innerWidth - 10;
  const columns = 50;
  const rowHeight = Math.trunc(width / columns);
  const { locked } = useLock();
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { activeTab } = useActiveTab();
  const layout = useRef<Layout[]>(
    activeTab ? tabConfigs[activeTab].layout : [],
  );
  const { uiRevision, setUiRevision } = useUiRevision();
  const [ focusedPlotId, setFocusedPlotId ] = useState<string | undefined>();
  return (
    <div>
      {activeTab && (
        <GridLayout
          className="layout"
          cols={columns}
          rowHeight={rowHeight}
          width={width}
          isBounded={true}
          isDraggable={!locked}
          isResizable={!locked}
          margin={[5, 5]}
          layout={layout.current}
          onLayoutChange={(newLayout: Layout[]) => {
            layout.current = newLayout;
          }}
          onDragStop={(newLayout: Layout[]) => {
            tabConfigs[activeTab].layout = newLayout;
            setUiRevision(uiRevision + 1);
            setTabConfigs({ ...tabConfigs });
          }}
          onResizeStop={(newLayout: Layout[]) => {
            tabConfigs[activeTab].layout = newLayout;
            setUiRevision(uiRevision + 1);
            setTabConfigs({ ...tabConfigs });
          }}
        >
          {Object.keys(tabConfigs[activeTab].plotConfigs).map(
            (plotId): React.ReactElement => {
              return (
                <PlotWrapper
                  tabId={activeTab}
                  plotId={plotId}
                  focusedPlot={focusedPlotId}
                  setFocusedPlot={setFocusedPlotId}
                  sampleMode={sampleMode}
                  key={`${plotId}-wrapper`}
                />
              );
            },
          )}
        </GridLayout>
      )}
    </div>
  );
}
