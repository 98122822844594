import {
  SegmentedControl,
  Popover,
  TextInput,
  Button,
  Space,
  NumberInput,
} from "@mantine/core";
import { useState } from "react";
import { useActiveTab } from "../contexts/ActiveTabContext";
import { useApi } from "../contexts/ApiContext";
import { useTabConfigs } from "../contexts/TabConfigContext";
import { SourceSignals } from "../services/session/data/DataClasses";
import handleSelections from "../utils/HandleSelections";
import { useData } from "../contexts/DataContext";
import { useXAxisRange } from "../contexts/XAxisRangeContext";
import { useDisclosure } from "@mantine/hooks";

export default function ExportButton() {
  const [fileType, setFileType] = useState<string>(".csv");
  const [fileName, setFileName] = useState<string>("");
  const [exportType, setExportType] = useState<string>("Subsampled");
  const { activeTab } = useActiveTab();
  const { data } = useData();
  const { xAxisRange } = useXAxisRange();
  const { api } = useApi();
  const { tabConfigs } = useTabConfigs();
  const [loading, setLoading] = useState(false);
  const [sample, setSample] = useState<number | undefined | string>(undefined);
  return (
    <Popover withArrow>
      <Popover.Target>
        <Button
          disabled={true}
          style={{ pos: "top", paddingRight: 10, paddingLeft: 10 }}
          size="sm"
          onClick={() => {}}
        >
          Export
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <TextInput
          placeholder="File name"
          value={fileName}
          onChange={(event) => setFileName(event.currentTarget.value)}
        ></TextInput>
        <Space h={10} />
        <SegmentedControl
          fullWidth
          value={exportType}
          onChange={setExportType}
          data={["Raw", "Subsampled"]}
        ></SegmentedControl>
        {exportType === "Subsampled"
          ? [
              <Space h={10} />,
              <NumberInput
                placeholder="Sample"
                value={sample}
                onChange={setSample}
              />,
            ]
          : undefined}
        <Space h={10} />
        <SegmentedControl
          value={fileType}
          onChange={setFileType}
          data={[".mat", ".csv", ".bin"]}
          fullWidth
        />
        <Space h={10} />
        <Button
          loading={loading}
          fullWidth
          onClick={() => {
            if (activeTab && xAxisRange) {
              const sourceSignals = handleSelections(
                undefined,
                undefined,
                tabConfigs[activeTab].plotConfigs,
                data,
              );
              if (sourceSignals) {
                setLoading(true);
                api.sessionApi.dataApi
                  .export(
                    sourceSignals,
                    xAxisRange[0],
                    xAxisRange[1],
                    exportType,
                    fileType,
                    fileName,
                    sample,
                  )
                  .then((res) => {
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                  });
              }
            }
          }}
        >
          Export
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
}
