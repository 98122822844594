import { Menu, Select, Grid, TextInput, Text } from "@mantine/core";
import React, { useState } from "react";

import { useTabConfigs } from "../../contexts/TabConfigContext";
import { useSignalNames } from "../../contexts/SignalNamesContext";
import YAxisConfigPopover from "./YAxisConfigPopover";
import { SignalNames } from "../../services/session/file/FileClasses";
import { sample } from "lodash";

interface Props {
  plotTitle: string;
  setPlotTitle: React.Dispatch<React.SetStateAction<string>>;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  left: number;
  top: number;
  tabId: string;
  plotId: string;
  localUiRevision: number;
  setLocalUiRevision: React.Dispatch<React.SetStateAction<number>>;
  menuFocus: boolean;
  setMenuFocus: React.Dispatch<React.SetStateAction<boolean>>;
  sampleMode: string;
}

export default function PlotMenu({
  plotTitle,
  setPlotTitle,
  opened,
  setOpened,
  left,
  top,
  plotId,
  tabId,
  localUiRevision,
  setLocalUiRevision,
  menuFocus,
  setMenuFocus,
  sampleMode,
}: Props) {
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  const { signalNames } = useSignalNames();
  const [focusedYAxis, setFocusedYAxis] = useState<number | undefined>();
  const handleYAxesItems = () => {
    return tabConfigs[tabId].plotConfigs[plotId].yAxes.map((yAxis, i) => {
      return (
        <YAxisConfigPopover
          key={`${tabId}-${plotId}-yAxis${i}-popover`}
          yAxisNum={i + 1}
          tabId={tabId}
          plotId={plotId}
          plotMenuOpened={opened}
          yVars={yAxis.vars}
          localUiRevision={localUiRevision}
          setLocalUiRevision={setLocalUiRevision}
          focusedYAxis={focusedYAxis}
          setFocusedYAxis={setFocusedYAxis}
          sampleMode={sampleMode}
        />
      );
    });
  };
  let xSelectData: string[] | SignalNames = [];
  if (signalNames.length > 0) {
    xSelectData = signalNames;
  } else if (tabConfigs[tabId].plotConfigs[plotId].xVar) {
    xSelectData = [
      JSON.parse(JSON.stringify(tabConfigs[tabId].plotConfigs[plotId].xVar)),
    ];
  }
  const addYAxisDisabled = tabConfigs[tabId].plotConfigs[plotId].yAxes.filter(
      ({axis})=> axis.side === "right"
  ).length > 0;
  return (
    <Menu
      opened={opened}
      onOpen={() => {
        setMenuFocus(true);
      }}
      onClose={() => {
        setMenuFocus(false);
      }}
      closeOnClickOutside={true}
      closeOnEscape={true}
      styles={{
        dropdown: { position: "absolute", left: left, top: top },
      }}
    >
      <Menu.Dropdown
        onMouseOut={() => {
          setMenuFocus(false);
        }}
        onMouseEnter={() => {
          setMenuFocus(true);
        }}
        onMouseOver={() => {
          setMenuFocus(true);
        }}
        onBlur={() => {
          if (!menuFocus) {
            setOpened(false);
          }
          tabConfigs[tabId].plotConfigs[plotId].title = plotTitle;
          setTabConfigs({ ...tabConfigs });
        }}
      >
        <Menu.Label>
          <Grid columns={15} justify="center" align="center">
            <Grid.Col span={3}>
              <Text fw={300} size="lg">
                Title
              </Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <TextInput
                value={plotTitle}
                style={{ width: 170 }}
                styles={{ root: { padding: 0 } }}
                onChange={(e) => {
                  setPlotTitle(e.currentTarget.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setOpened(false);
                  }
                }}
              ></TextInput>
            </Grid.Col>
            <Grid.Col span={2} />
          </Grid>
        </Menu.Label>
        <Menu.Label>
          <Grid justify="flex-start" align="center">
            <Grid.Col span={2}>
              <Text fw={700} size="sm">
                Axes
              </Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <Menu.Divider />
            </Grid.Col>
          </Grid>
        </Menu.Label>
        <Menu.Label>
          <Grid columns={15} justify="center" align="center">
            <Grid.Col span={3}>
              <Text fw={300} size="lg">
                X
              </Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <Select
                searchable
                clearable
                style={{ width: 170 }}
                value={tabConfigs[tabId].plotConfigs[plotId].xVar}
                onChange={(value) => {
                  tabConfigs[tabId].plotConfigs[plotId].xVar = value;
                  setTabConfigs({ ...tabConfigs });
                }}
                data={xSelectData}
              />
            </Grid.Col>
            <Grid.Col span={2} />
          </Grid>
        </Menu.Label>
        {handleYAxesItems()}
        <Menu.Item
            disabled={addYAxisDisabled}
          onClick={() => {
            tabConfigs[tabId].plotConfigs[plotId].yAxes.push({
              vars: {},
              axis: {
                title: undefined,
                side: "left",
                range: undefined,
                autoscale: true,
              },
            });
            setTabConfigs({ ...tabConfigs });
          }}
        >
          <Text fw={500} ta="center">
            Add Y Axis
          </Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={() => {
            delete tabConfigs[tabId].plotConfigs[plotId];
            setTabConfigs({ ...tabConfigs });
          }}
        >
          <Text ta="center" style={{ color: "red" }} fw={500}>
            Delete Plot
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
