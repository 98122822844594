import React from "react";
import { Menu } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

import { useTabConfigs } from "../../contexts/TabConfigContext";

interface Props {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  left: number;
  top: number;
  tabId: string;
}

export default function TabMenu({
  opened,
  setOpened,
  left,
  top,
  tabId,
}: Props) {
  const { tabConfigs, setTabConfigs } = useTabConfigs();
  return (
    <Menu
      opened={opened}
      closeOnClickOutside={true}
      closeOnEscape={true}
      styles={{ dropdown: { position: "absolute", left: left, top: top } }}
    >
      <Menu.Dropdown
        onBlur={() => {
          setOpened(false);
        }}
      >
        <Menu.Item
          onClick={() => {
            delete tabConfigs[tabId];
            setTabConfigs({ ...tabConfigs });
          }}
          color="red"
          leftSection={<IconTrash />}
        >
          Delete Tab
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
