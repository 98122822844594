import { useData } from "../contexts/DataContext";
import { useTabConfigs } from "../contexts/TabConfigContext";
import {
  SampledData,
  SourceSignals,
} from "../services/session/data/DataClasses";
import { PlotConfigs } from "../services/session/SessionClasses";

export default function handleSelections(
  selections: string[] | undefined,
  group: string | undefined,
  plotConfigs: PlotConfigs,
  data: SampledData,
): SourceSignals | undefined {
  // Check if data already exists for all selections
  if (selections && group) {
    let exit = true;
    for (const selection of selections) {
      if (
        !data.hasOwnProperty(group) ||
        !data[group].hasOwnProperty(selection)
      ) {
        exit = false;
        break;
      }
    }
    if (exit) {
      return;
    }
  }
  const sourceSignalSets: { [sourceName: string]: Set<string> } = {};
  for (const { yAxes, xVar } of Object.values(plotConfigs)) {
    if (
      xVar &&
      Object.values(
        yAxes.flatMap(({ vars }) => {
          return Object.keys(vars).flat();
        }),
      ).length > 0
    ) {
      for (const { vars } of Object.values(yAxes)) {
        for (const [source, signals] of Object.entries(vars)) {
          if (!sourceSignalSets.hasOwnProperty(source)) {
            sourceSignalSets[source] = new Set(signals.concat(xVar));
          } else {
            for (const signal of signals) {
              sourceSignalSets[source].add(signal);
            }
            sourceSignalSets[source].add(xVar);
          }
        }
      }
    }
  }
  const sourceSignals: SourceSignals = {};
  for (const [source, signalsSet] of Object.entries(sourceSignalSets)) {
    sourceSignals[source] = Array.from(signalsSet);
  }
  return sourceSignals;
}
