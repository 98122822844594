import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import React, { useEffect, useState } from "react";

import DirectoryItem from "./DirectoryItem";
import { Directory } from "../../services/session/file/FileClasses";
import { useApi } from "../../contexts/ApiContext";
import { useFileDirectory } from "../../contexts/FileDirectoryContext";

function updateNestedDirectory(
  directory: Directory,
  keys: string[],
  updatedDirectory: Directory,
): Directory {
  if (keys.length === 0) {
    return updatedDirectory;
  }
  const [key, ...remainingKeys] = keys;
  if (directory.directories && directory.directories.hasOwnProperty(key)) {
    return {
      ...directory,
      directories: {
        ...directory.directories,
        [key]: updateNestedDirectory(
          directory.directories[key],
          remainingKeys,
          updatedDirectory,
        ),
      },
    };
  } else {
    console.error(`Key ${key} not found in directories`);
    return directory;
  }
}

interface Props {
  setBzFiles: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedBz: React.Dispatch<React.SetStateAction<string | null>>;
  bzDropdownOpenTrig: boolean;
}

export default function DirectoryTreeView({
  setBzFiles,
  setSelectedBz,
  bzDropdownOpenTrig,
}: Props) {
  const { api } = useApi();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>("");
  const [directoryTree, setDirectoryTree] = useState<Directory | undefined>();
  const { setFileDir } = useFileDirectory();
  useEffect(() => {
    let fileDir = "";
    let keys: string[] = [];
    if (directoryTree) {
      keys = selected.endsWith(".bz")
        ? selected.split("/").slice(2, -1)
        : selected.split("/").slice(2);
      fileDir = keys.join("/");
    }
    api.sessionApi.fileApi.systemEntries(fileDir).then((apiRes) => {
      if (apiRes) {
        setBzFiles(apiRes.bzFiles ? apiRes.bzFiles : []);
        setDirectoryTree(
          !directoryTree
            ? apiRes
            : updateNestedDirectory(directoryTree, keys, apiRes),
        );
      }
    });
    setFileDir(fileDir);
    if (selected.endsWith(".bz")) {
      const path = selected.split("/");
      const selectedBz = path.pop();
      setSelectedBz(selectedBz ? selectedBz : null);
      setSelected(path.join("/"));
    }
  }, [selected, bzDropdownOpenTrig]);
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => {
    setSelected(nodeIds);
  };
  return directoryTree ? (
    <TreeView
      aria-label="controlled"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      <DirectoryItem parentDirectoryPath="" directory={directoryTree} />
    </TreeView>
  ) : null;
}
